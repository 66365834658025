import styled from 'styled-components';

import { WIDTH } from '../../constants/screenResolution';

export const BodyContainer = styled.div`
  @media (min-width: 480px) {
    margin: 0 20px;
    justify-content: start;
  }
  @media (min-width: 800px) {
    display: flex;
    margin: 0 20px;
  }
  @media (min-width: 1200px) {
    max-width: 1120px;
    width: 100%;
    margin: auto;
  }
  display: flex;
  height: calc(100vh - 108px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  margin: 0 16px;
`;

export const LostMessageContainer = styled.div`
  font-size: 48px;
  line-height: 56px;
  font-family: Onest-Bold;
  text-align: center;
  max-width: 881px;
  margin-bottom: 32px;
  font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
  color: ${(props) => props.theme.palette.text.t1};
  margin-top: 80px;
  @media (max-width: ${WIDTH.mobileMax}) {
    font-size: 24px;
    line-height: 28px;
    width: 100%;
    margin-bottom: 24px;
    margin-top: 48px;
  }
`;

export const PageNotFoundSubtitle = styled.p`
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.theme.palette.text.t1};
  max-width: 500px;
  text-align: center;
  font-family: Onest-Medium;
  margin-bottom: 24px;
  margin-top: 40px;
  @media (max-width: ${WIDTH.mobileMax}) {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 18px;
    max-width: 327px;
  }
`;
