import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import SiteLayout from '../Layout/SiteLayout';
import Button from '../components/NewButton/Button';
import { WIDTH } from '../constants/screenResolution';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { getNumFromResolutionConstant } from '../utils/getNumberFromResolutionConstant';

import Link from 'next/dist/client/link';
import { useLocale } from '../contexts/LocalizationContext';
import { fetchCountryList } from '../redux/globalSlice';
import { useAppDispatch } from '../redux/hooks';
import {
  BodyContainer,
  LostMessageContainer,
  PageNotFoundSubtitle,
} from '../styles/pageStyles/404Style';
import { getPageData, saveEventV3 } from '../utils/eventTracking';

const PageNotFound = () => {
  const router = useRouter();
  const { width } = useWindowDimensions();
  const dispatch = useAppDispatch();

  const { messages } = useLocale();
  const { page_not_found_title_msg, explore_msg, page_not_found_subtitle_msg } =
    messages || {};

  useEffect(() => {
    dispatch(fetchCountryList());
  }, []);

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    const pageData = getPageData(router);

    saveEventV3({
      category: pageData.pageName,
      action: 'page_view',
      label: pageData.pageLabel,
      properties: 'page_404',
      value: [pageData.pageValue],
      jsonData: {
        query: pageData.query,
      },
      from: router,
    });
  }, [JSON.stringify(router)]);

  return (
    <SiteLayout>
      <BodyContainer>
        <LostMessageContainer>{page_not_found_title_msg}</LostMessageContainer>
        <Image
          src={'https://static.driffle.com/images/404.webp'}
          width={
            width && width > getNumFromResolutionConstant(WIDTH.mobileMax)
              ? '556px'
              : '298px'
          }
          height={
            width && width > getNumFromResolutionConstant(WIDTH.mobileMax)
              ? '218px'
              : '117px'
          }
          layout="fixed"
          alt="404"
        />
        <PageNotFoundSubtitle>
          {page_not_found_subtitle_msg}
        </PageNotFoundSubtitle>
        <Link passHref href="/">
          <a>
            <Button
              // onClick={() => {
              //   router.push('/');
              // }}
              ariaLabel="go home"
              width="180px"
              height="48px"
            >
              {explore_msg} Driffle
            </Button>
          </a>
        </Link>
      </BodyContainer>
    </SiteLayout>
  );
};

export default PageNotFound;
